html {
  scroll-behavior: smooth;
}

html, body { 
  font-weight: 400; 
  height: 100vh; 
  width: 100%; 
  color: $color-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
  
::-moz-selection {
  color: #fff;
  background: $color-bg-nav;
}

::selection {
  color: #fff;
  background: $color-bg-nav;
}

a {
  outline: none !important;
}

strong, .strong {
  font-weight: 500;
}

p { 
  font-size: $base-font-size;
  line-height: 1.4;
  margin-bottom: 1rem !important;
}

.small-text {
  font-size: $small-font-size;
}

hr {
  margin-top:10px;
  margin-bottom:14px;
}

u, .underline {
  text-decoration: none;
  border-bottom: 1px dotted #000;
}

h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5 {
  font-weight: 400;
  color: $color-text;
}

h1, .h1 {
  font-size: $h1-font-size;
  line-height: 1.2;
}

h1 + p {
  margin-top: 40px;
}

h2, .h2 {
  font-size: $h2-font-size;
  line-height: 1.25;
}

h2 {
  font-size: $base-font-size;
  padding-top: 5rem;
}

h3, .h3 {
  font-size: $h3-font-size;
  line-height: 1.3;
}

h4, .h4 {
  font-size: $h4-font-size;
  line-height: 1.4;
}

h5, .h5 {
  font-size: $h5-font-size;
  line-height: 1.4;
}

figcaption {
  margin: .5rem 0 0;
  font-size: .875rem;
}

.bg-grey {
  background-color: $bg-grey;
}

.bg-blue {
  background-color: $bg-blue !important;
}

.bg-yellow {
  background-color: $bg-yellow !important;
}

.bg-green {
  background-color: $bg-green !important;
}

.trans {
  mix-blend-mode: multiply;
}

.dot {
  display: block;
  width: .75rem;
  height: .75rem;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  margin: .125rem 0 .375rem;

  &.auswahl {
    background-color: $dot-green;
  }

  &.detail {
    background-color: $dot-blue;
  }

  &.bau, &.wettbewerb, &.studie {
    width: 1.25rem;
    height: 1.25rem;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      font-size: .875rem;
      font-weight: 700;
      margin-top: 1px;
    }
  }

  &.bau {
    &:after {
      content: 'B';
    }
  }

  &.wettbewerb {
    &:after {
      content: 'W';
    }
  }

  &.studie {
    &:after {
      content: 'S';
    }
  }
}

#mapcontainer {
  width: 100%;
  height: 600px;
  border: 0;
  margin-bottom: 40px;

  @media only screen and (max-width:576px) {
    height: 450px;      
  }
}

span.fehler { margin-bottom:9px; }
span.fehler:last-of-type { margin-bottom:0; }
span.success { display:block; color:green; }

.vorschau {

  overflow-x: hidden;
  a.ico { position:fixed; top:18px; right:18px; z-index:1000; text-decoration: none; }
  .refresh { margin:0; cursor:pointer; font-size:24px; color:#d00; }
  .refresh:hover { color:#909090; }
  .container { padding-left:10px; padding-right:10px; }

}

body {
  font-family: 'Public', Arial, sans-serif !important;
  font-size: $base-font-size; 
  display: flex; 
  min-height: 100vh; 
  flex-direction: column; 
  overflow-x: hidden;
  background-color: $bg-grey;

  /* ------------ HEADER / MENU ------------ */
  header, #main-nav {
    a, &:hover, &.current {
      text-decoration: none;
    }
  }

  #main-nav {
    margin: 0 1rem 0 0;
  
    @media only screen and (max-width:991px) {
      margin:0 1rem;      
    }

    ul {      
      position: relative;
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 0;
      background: linear-gradient(to right,#bababa,#bababa);
      background-position-x: 0%;
      background-position-y: 0%;
      background-repeat: repeat;
      background-size: auto;
      background-size: 100% 2px;
      background-position: left top;
      background-repeat: no-repeat;

      li {
        float:left;
        margin: 0 1rem 0 0;
        padding: .75rem 0 1rem 0;
        width: 25%;        
        border-top: 2px solid transparent;

        @media only screen and (min-width:992px) and (max-width:1120px) {
          width: auto;     
        }

        &:hover, &.current {
          border-top: 2px solid $color-text;
        }

        a {
          color: $color-grey;
          
          &:hover, &.current, &.active {
            color: $color-text;
          }
        } 

        &.current, &.active {
          border-color: #000;

          a {
            color: $color-text;
          }
        }

        &.has-children {  

          a {
            display: inline-block;
            position: relative;
            width: 100%;
            color: $color-grey;

            @media only screen and (max-width:1120px) and (min-width:992px) {
              padding-right: 0;
            }
            
            @media only screen and (max-width:991px) {
              padding-right: .5rem;
            } 

            &::after {
              position: absolute;
              top: calc(50% - .5rem);
              right: 0;
              font-family: Phosphor;
              font-size: 1rem;
              content: "\ebd1";
              transition: all .1s linear;
            }  
            
            @media only screen and (min-width:992px) and (max-width:1120px) {
              &::after {
                content: "";
              }
            }

            @media only screen and (max-width:480px) {
              &::after {
                content: "";
              }
            }

            &:hover {
              color: $color-text;
            }

            &:not(.collapsed) {
              color: $color-text;

              &::after {
                top: calc(50% - .5rem);
                transform: rotate(180deg);
              }                  
            }
          }

          ul { 
            position: absolute;
            left: 0;
            right: 0;
            top: 3rem;
            width: 100%;
            list-style: none;
            background: linear-gradient(to right,#bababa,#bababa);
            background-position-x: 0%;
            background-position-y: 0%;
            background-repeat: repeat;
            background-size: auto;
            background-size: 100% 2px;
            background-position: left top;
            background-repeat: no-repeat;
            background-color: $bg-grey;
                
            li {
              width: auto;
              margin: 0 1.5rem 0 0;
              padding: .5rem 0 .75rem 0;

              &.current a {
                color: $color-text !important;
              }

              a {
                font-size: 80%;
                display: inline-block;
                padding-right: 0;
                color: $color-grey !important;

                &:hover {
                  color: $color-text !important;
                }

                &:after {
                  content: "";
                }
              }
            }
          }
          
          &.bg-blue {
            background-color: $bg-blue;
          }
          
        } 
      }
    }
  }

  /* ------------ Header ------------ */

  header {
    z-index: 12;
    position: relative;

    #header-wrap {
      padding: 0;
      position: fixed;
      z-index: 12000;
      right: 0;
      left: 0;
      top: 0;

      &.headroom {
        will-change: transform;
        transition: transform 200ms linear;
      }
      &.headroom--pinned {
        transform: translateY(0%);
      }
      &.headroom--unpinned {
        transform: translateY(-100%);
      }
      &.headroom--not-top {
        box-shadow: 0px 5px 15px 5px rgba(0,0,0,0.07);
      }

      .white-bg {
        padding-top: 1.5rem;
        padding-bottom: .75rem;
        background-color: $bg-grey;
        height: 8rem;

        @media only screen and (max-width:959px) {
          height: 11rem;      
        }

        .h-auto {
          height: auto;

          @media only screen and (max-width:959px) {
            height: 1rem !important;      
          }
        }
      }

    }

    #logo {
      display: block;
      height: auto;
      color: $color-text;
      background: linear-gradient(to right,#000,#000);
      background-position-x: 0%;
      background-position-y: 0%;
      background-repeat: repeat;
      background-size: auto;
      background-size: 100% 2px;
      background-position: left top;
      background-repeat: no-repeat;
      padding: .75rem 0;
      margin: 0 0 0 1rem;

      @media only screen and (max-width:991px) {
        margin:0 1rem;      
      }
    }
      
   .menu-text, #logo, #main-nav {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1;

      @media only screen and (max-width:480px) {
        font-size: 1.375rem;      
      }
    }
  }

  &.intro {
    header {
      mix-blend-mode: difference;

      #header-wrap {

        .white-bg {
          background-color: transparent;

        }

        #logo {
          display: block;
          height: auto;
          color: #fff;
          background: linear-gradient(to right,#fff,#fff);
          background-position-x: 0%;
          background-position-y: 0%;
          background-repeat: repeat;
          background-size: auto;
          background-size: 100% 2px;
          background-position: left top;
          background-repeat: no-repeat;
          padding: .75rem 0;
          margin: 0 1rem 0 1rem;

          @media only screen and (max-width:959px) {
            margin: 0 1rem;      
          }
        }
      }
    }

    main {
      padding-top: 0;

      .container-fluid {
        padding: 0;

        #splide {
          ul {
            list-style-type: none;
            padding: 0;
          }
        }

        #enter {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          bottom: 2rem;
          left: calc(50vw - 1.25rem);
          z-index: 9998;
          width: 2.5rem;
          height: 2.5rem;
          color: #000;
          background: #fff;
          border: 1px solid #000;
          border-radius: 100%;
          line-height: 1;
          font-size: 1.75rem;
          text-decoration: none;
          cursor:pointer;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  #indikator {
    display:none;      

    @media only screen and (min-width:1221px) {
      display: block;
      position: fixed; 
      z-index: 11; 
      top: 0; 
      left: 0;
      background: $bg-grey;
  
      &.verlauf {
        background: rgb(255,255,255);
        background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.35) 50%, rgba(255,255,255,1) 100%);   
      }
    }
  }

  .table-responsive {
    padding: 0;
  }

  .table {      
    caption {
      font-size: .875rem;
      color: $color-text;

      .form-check {
        display: inline-flex;
        margin: 0 1rem 0 0;
        padding-left: .125rem;
        align-items: center;

        .dot {
          display: inline-block;
          margin: auto 0 auto .25rem;
        }

        input {
          font-size: 1.125rem;
          border-radius: 0;
          border: 0;
          border-bottom: 1px solid #000;
          background-color: #fff;
          margin: 0 .375rem 0 0;
          box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);

          &:checked {
            background-color: #000;
          }

          &[type=radio] {
            border-radius: 100%;
            border-bottom: 0;
          }
        }

      }

      .form-check:first-of-type {
        padding-left:.375rem;
      }

      .filterabstand {
        margin-right: 4rem;
      }

    }

    thead {
      position: sticky;
      top: 0;
    }

    th {
      vertical-align: middle;
      font-weight: 400 !important;
      font-size: .875rem;
    }

    td {
      vertical-align: middle;

      &.p-titel {
        font-size: 1.5rem;
        font-weight: 500;
      }

      a {
        border: 0;

        &:hover {
          border-bottom: 1px solid #000
        }

        &.dot {
          &:hover {
            border: 0;
          }
        }
      }
    }
  }

  main {
    padding-top: 14rem;
    
    @media only screen and (max-width:479px) {
      padding-top: 12rem;      
    }

    &.projekte, &.seiten {
      padding-top: 0;

      .col-md-6 {
        margin-bottom: 1.5rem;
      }

      .p-intro {
        h1 {
          font-size: 10rem;
          line-height: .95;
          margin: 0;

          @media only screen and (max-width:1199px) {
            font-size: 9rem;      
          }

          @media only screen and (max-width:991px) {
            font-size: 8rem;      
          }

          @media only screen and (max-width:576px) {
            font-size: 7rem;      
          }

          @media only screen and (max-width:479px) {
            font-size: 6rem;      
          }
        }

        p {
          font-size: 1rem;
          margin: 0 0 0 .5rem;
        }

        h1 + p {
          margin-top: 0;
        }
        
        .p-info {
          position: absolute;
          z-index: 2;
          bottom: 1rem;

          h1 {
            word-spacing: 100vw;
            hyphens: auto;
            text-shadow: 0 0 80px rgba(255,255,255,.7);
          }
        }

        figure {   
          margin-top:9rem; 
          height: calc(100vh - 9rem);
          width: 100vw;
          text-align: center;

          @media only screen and (min-device-width: 374px) and (max-device-width: 1024px) and (orientation:portrait) {
            margin-top: 18rem;
            height: calc(100vh - 18rem);
          }

          img {
            max-width: 90%;
            max-height: 90%;
          }
        }
      }

      .p-texte {
        padding: 4rem .5rem;
        min-height: 100vh;

        hr {
          border: 0;
          height: 2px;
          background: #000;
          margin: 0 0 2rem;
          opacity: 1;
        }

        h2, .h2 {
          font-size: 1.5rem;
          margin: 0;
          padding-top: 0;
        }

        p + h2, p + .h2 {
          margin-top: 30px;
        }

        h3, .h3, .info, .infolabel { 
          margin: 0;
        }

        .info {
          margin-bottom: 1rem;
          margin-top: 0;
        }

        h3, .h3, .infolabel {
          text-transform: uppercase;
          margin-bottom: 0 !important;
          font-size: 1rem;
        }
      }

      .p-bilder {
        figure {
          min-height: 100vh;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: url(/img/arrow-circle-down-fill.png), auto;

          &:last-of-type {
            cursor: auto;
          }

          @media only screen and (max-width:959px) {
            min-height: auto;
            margin-bottom: 4rem;      
          }

          img {
            max-height: 88vh;
          }
        }
      }

      .pager {
        a {
          border: 0;
          text-decoration: none;

          &.prev-p, &.next-p {
            .pager-button {
              border-bottom: 2px solid $color-text;
              padding: .25rem 0 .5rem;
              position: relative;
              transition: all .2s ease-in;
              line-height: 1.25;

              &:before {
                position: absolute;
                bottom: 0;
                font-family: Phosphor;
                font-size: 1.25rem;
              }

              &:hover {
                background: rgb(255,255,255);
                background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(245,245,245,1) 100%);
                padding: .25rem .75rem .5rem;
              }

              .projekt-titel {
                font-size: 1.25rem;
                font-weight: 500;                
              }
            }
          }

          &.next-p {
            .pager-button {
              text-align: right;
  
              &:before {
                left: .5rem;
                content: "\ea0a";
              }
            }
          }

          &.prev-p {
            .pager-button {  
              &:before {
                right: .5rem;
                content: "\e9dd";
              }
            }
          }
        }
      }
    }

    &.seiten {
      padding-top: 14rem;
  
      @media only screen and (max-width:479px) {
        padding-top: 12rem;      
      }
    }

    .next {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 2rem;
      left: calc(50vw - 1.25rem);
      z-index: 3;
      width: 2.5rem;
      height: 2.5rem;
      color: #000;
      background: #fff;
      border: 0;
      border-radius: 100%;
      line-height: 1;
      font-size: 1.75rem;
      text-decoration: none;
      cursor:pointer;
      transition: transform 500ms;
      box-shadow: 0 0 .5rem #666;

      &:hover {
        border: 0;
        transform: translateY(.75rem);
      }
    }

    .item {
      transition: all .2s ease-in;
      text-decoration: none;
      margin-bottom: 2.5rem;
      border: 0;

      .item-wrap {
        border-bottom: 1px solid $color-grey;
      }

      .img-box {
        opacity: 1;
        display: block;
        transition: opacity .5s ease-out;

        img {
          max-height: 100%;
          max-width: 100%;
          filter: grayscale(100);
        }

        @media only screen and (max-width:575px) {
          &.ratio-1x1 {
            @include media-breakpoint-down(md) {
              --bs-aspect-ratio: 50%; // 2x1
            }
          }                
        }
        
      }

      .h2 {
        display: block;
        color: $color-grey;
        font-size: 1.5rem;
        padding: 0 .5rem;
      }

      .title {
        min-height: 3.5rem;
        display: flex;
        align-items: flex-end;
      }
      
      .text {
        display: block;
        color: $color-grey;
        width: 100%;
        font-size: 1rem;
        padding: 0 .5rem 1rem;
        line-height: 1.2;
      }

      &:hover {
          text-decoration: none;
          border-bottom: 0;
          
          .title {
            display:block;
          }
      }

      @media only screen and (min-width:576px) {
        &:hover {
          
          .item-wrap {
            height: 100%;
            background-color: #000;
            border-bottom: 1px solid #000;
          }

          .h2 {
            margin: auto 0;
            text-align: center;
            font-size: 3rem;
            hyphens: auto;
            word-spacing: 100vw;
            color: #fff;
          }

          .text {
            color: #fff;
          }

          .img-box {
            display: none;
            opacity: 0;
          }
        }
      }
    }

    ul {
      padding: 0 0 0 1.25rem;
    }

    p+ul {
      margin-top: -1rem;
    }

    h3+p {
      margin-top: 1rem;
    }

    ul.download-linkliste {
      list-style: none;
      margin: 30px 0 0;
      padding: 0 !important;

      li {
        margin: 0;
        a {
          i { display: none; }
        }
      }
      li::before {
          content:"" !important;
        }
    }

    section {
      a {
        text-decoration: none;
        border-bottom: 1px solid #333;
        color: #333;

        &.text-danger {
          color: $color-red;
          border-bottom: 1px solid $color-red;
        }

        &:hover {
          text-decoration: none;
          border-bottom: 1px solid #000;
          color: #000;
        }
      }

      .embed-responsive-1by1, .embed-responsive-4by3 {
        background-size: cover;
        background-position: center center;
        display: flex;
        border: 0;
        transition: all .3s ease-in-out;

        &:hover {
          background-color: rgba(0, 0, 252, .5);
          transition: all .3s ease-in-out;
          border: 0;
        }
      }
    }

    .content-section {
      padding: 3.75rem 0 2.5rem;

      ul {
        list-style: none;

        li {
          &:before {
            content: "•";
            display: inline-block; 
            width: 1em;
            margin-left: -1em;
          }
        }
      }

      ul { 
        font-size: $base-font-size;
        padding-left: 1.3em;
        margin: 0 0 1.25rem;
        list-style-type: none;

        li {
          margin-bottom: .625rem;
        
          &:before {
            content: '\2014';
            position: absolute;
            margin-left: -1.75rem;
          }
        }
      }

      form {
        input, select, textarea {
          border: 0;
          border-radius: 0;
          box-shadow: none;
          border-bottom: 1px solid #ccc;
          padding: .75rem .5rem !important;
          min-height: 3rem;
          margin: .5rem 0;
        }

        textarea {
          min-height: 120px;
        }

        select.custom-select {
          background: url(/img/select_arrow.svg) 98% center no-repeat;
          background-color: #fff;
        }

        /* ------------ Forms ------------ */
        .form-control {
          color: #343638; 

          &::placeholder {
            color: #343638;
            opacity: 0; 
          }
        }

        .form-control:focus, .custom-select:focus {
          color: $color-text-dark;
          background-color: #fff;
          border: 0 !important;
          border-bottom: 2px solid #000 !important;
          outline: 0;
          box-shadow: none;
          padding-bottom:12px !important;
        }
        textarea.form-control:focus {
          padding: 6px 5px !important;
        }

        .form-check {
          padding-left: 0;

          .form-check-input {
            -webkit-appearance: none;
            display:none;
          }

          .form-check-input + label::before {
            width: 14px;
            height: 14px;
            border-radius:1px;
            border: 1px solid #333;
            background-color: #fff;
            display: block;
            content: "";
            float: left;
            margin-top:1px;
            margin-right: 9px;
            margin-bottom: 6px;
          }

          .form-check-input:checked + label::before {
            box-shadow: inset 0 0 0 2px #fff;
            background-color: #000;
          }

          label {
            font-size: 1rem;
            color: $color-text-dark;
          }
        }


        .form-group {
          position: relative;
          border-bottom: 0;
          margin-bottom: 1.25rem;
          label {
            position: absolute;
            top: 45%;
            left: .75rem;
            transform: translate3d(0, -50%, 0);
            transition: all .3s;
            padding: .5rem 0 0;
            font-size: 85%;
            color: #666;
          }

          label.txtarea {
            top:24px;
          }
          label.top0 { top: -.75rem; }
        
          input:not(:placeholder-shown) + label,
          input.nep + label,
          input:focus + label,
          textarea.txtarea:not(:placeholder-shown) + label,
          textarea.nep + label,
          textarea.txtarea:focus + label{
            top: -.75rem;
          }
        }


        .description {
          font-size: 13px;
          line-height: 1.3;
        }

        .btn-red-border {
          padding: 12px 25px !important;
          font-size: 14px;
          background-color: #fff;
          color: #000;
          border: 2px solid #000;
          border-radius: 3px;
          transition: all .3s linear;
          text-transform: uppercase;
          letter-spacing: 1px;
          line-height: 12px;

          &:hover {
            background-color: #000;
            color: #fff;
          }
        }
      }

      a {
        &.ico-link {
          display: flex;
          margin-bottom: 12px;

          i {
            margin-right: 12px;
            font-size: 21px;
          }

          span {
            display: inline-flex;
            width: auto;
          }
        }
        &:hover {
          text-decoration: none;
        }
      }

      .wysiwyg {
        .card {
          margin-bottom: 2rem;

          .card-text {
            min-height: 3rem;
          }
        }

        hr {
          border: 0;
          height: 2px;
          background: #000;
          margin: 0 0 2rem;
          opacity: 1;
        }

        p {
          -webkit-hyphens: auto;
          -ms-hyphens: auto;
          hyphens: auto;
        }

        h2, .h2 {
          font-size: 1.5rem;
          line-height: 1.3;
          margin: 0 0 .25rem;
          padding-top: 0;
        }

        p + h2, p + .h2 {
          margin-top: 30px;
        }

        h3, .h3, .info, .infolabel { 
          margin: 0;
        }

        .info {
          margin-bottom: 1rem;
          margin-top: 0;
        }

        h3, .h3, .infolabel {
          text-transform: uppercase;
          margin-bottom: 0 !important;
          font-size: 1rem;
        }

        .accordion {
          margin-bottom: 1.5rem;

          details {
            summary {
              list-style-type: none;

              &::-webkit-details-marker {
                display: none;
              }

              &:before {
                content: '';
              }

              &:after {
                font-family: Phosphor;
                content: '\ebd1';
                margin-left: .25rem;
              }
            }
          }

          details[open] {
            summary::before {
              content: '';
            }

            summary::after {
              font-family: Phosphor;
              content: '\ebe0';
              margin-left: .25rem;
            }
          }
        }
      }
    }

    hr {
      background-color: $color-light-grey;
    }

    .side hr:first-child {
      margin-top: 0;
    }

    .label {
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 1px;
      font-weight: 600;
    }
  }

  /* ------------ Accept Cookies ----------- */
  #cookie-bar {
    position: fixed; 
    z-index: 190; 
    right: 0; 
    bottom: 0; 
    left: 0; 
    padding: .5rem 0 1.5rem;
    background-color: blue;

    small, .h3, a {
      color: #fff;
    }

    .btn-outline-danger {
      border-color: #fff;

      &:hover {
        background-color: #fff;
        color: blue;
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -16px;
      left: 0;
      width: 100vw;
      height: 16px;
      background:
        linear-gradient(
          45deg, transparent 33.333%,
          blue 33.333%, blue 66.667%,
          transparent 66.667%
        ),
        linear-gradient(
          -45deg, transparent 33.333%,
          blue 33.333%, blue 66.667%,
          transparent 66.667%
        );
      background-size: 16px 32px;
    }

    a:not(.btn) {
      text-decoration: none;
      color: #fff;
      border-bottom: 1px solid #fff;
    }
  }

  /* ------------ Footer ------------ */
  footer {
    padding: 3.5rem 0 2rem;
    background-color: #fff; 
    color: $color-text;

    p {
      font-size: 1rem;
    }

    a {
      position: relative;
      text-decoration: none;
      line-height: 1.3;
      color: $color-text;
      padding-bottom: .125rem;
      
      &:after {
        border-top: .1em solid $color-text;
        content: "";
        position: absolute;
        right: 100%;
        bottom: 0;
        left: 0;
        transition: right .4s cubic-bezier(0,.5,0,1);
      }
      
      &:hover {
        text-decoration: none;
        color: $color-text;

        &:after {
          right: 0;
        }
      }
      &.current:after {
         right:0;
      }
    }
  }

  #back-to-top {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 2rem;
    right: 2rem;
    z-index: 9998;
    width: 2.5rem;
    height: 2.5rem;
    color: #000;
    background: #fff;;
    border: 0;
    border-radius: 100%;
    line-height: 1;
    font-size: 1.75rem;
    cursor:pointer;
    transition: transform 500ms;
    box-shadow: 0 0 .5rem #666;

    &:hover {
      border: 0;
      transform: translateY(-.75rem);
    }
  }
}
@keyframes fadeInOpacity {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

/* Media queries */

@media only screen and (min-width:476px) and (max-width: 1219px) {
  .container {
    max-width: 100%;
  }
}

@media only screen and (min-width:480px) and (max-width: 1025px) {
  body {
    main {
      .slider.home-slider .slide {
        max-height: 40rem;
      }
    }
  } 
}

@media (max-width:520px) {
  body {
    h1, .h1 {
      font-size: 2.5rem;
    }
    h3, .h3 {
      font-size: 1.5rem;
    }
  }
}



