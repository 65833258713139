@font-face {
    font-family: 'Public';
    src: url('/fonts/PublicSans-Regular.woff2') format('woff2'),
    url('/fonts/PublicSans-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Public';
    src: url('/fonts/PublicSans-Italic.woff2') format('woff2'),
    url('/fonts/PublicSans-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Public';
    src: url('/fonts/PublicSans-Medium.woff2') format('woff2'),
    url('/fonts/PublicSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Public';
    src: url('/fonts/PublicSans-MediumItalic.woff2') format('woff2'),
    url('/fonts/PublicSans-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Phosphor';
    src:
    url('/fonts/phosphor.ttf?smubu4') format('truetype'),
    url('/fonts/phosphor.woff?smubu4') format('woff'),
    url('/fonts/phosphor.svg?smubu4#phosphor') format('svg');
}

@font-face {
    font-family: 'Feather';
    src: url('/fonts/feather.eot?t=1525787366991'); /* IE9*/
    src: url('/fonts/feather.eot?t=1525787366991#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/feather.woff?t=1525787366991') format('woff'), /* chrome, firefox */
    url('/fonts/feather.ttf?t=1525787366991') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
    url('/fonts/feather.svg?t=1525787366991#feather') format('svg'); /* iOS 4.1- */
}